import React, { useEffect } from "react";
import ReactGA from "react-ga";
import UploadImageButton from "../../components/UploadImageButton";
import "./styles.css";

export default function UploadImageScreen(props) {
  const { setScreen, setImageData } = props;

  useEffect(() => {
    ReactGA.pageview("home");
  }, []);

  return (
    <div className={"landingContainer"}>
      <h1 className={"landingContainer__heading"}>Browser Blur</h1>
      <h3 className={"landingContainer__tagline"}>
        Blur sensitive areas on an image. All processing happens in the browser
        so your image never leaves your computer.
      </h3>
      <UploadImageButton
        className={"landingContainer__uploadButton"}
        variant={"solid"}
        onChange={imageData => {
          setImageData(imageData);
          setScreen("studio");
        }}
      >
        Select Image
      </UploadImageButton>
      <p>Simply drag on areas you want to blur and download</p>
      <img
        src="/demo.gif"
        className={"landingContainer__previewBox"}
        alt="Demo"
      />
      <footer className={"landingContainer__footer"}>
        Made with{" "}
        <span role="img" aria-label="coffee">
          ☕️
        </span>{" "}
        &{" "}
        <span role="img" aria-label="laptop">
          💻
        </span>{" "}
        by{" "}
        <a
          href="https://twitter.com/dhruvparmar"
          target="_blank"
          rel="noopener noreferrer"
        >
          @dhruvparmar
        </a>
        <br />
        <span role="img" aria-label="raised-hands">
          🙌
        </span>{" "}
        to{" "}
        <a
          href="https://twitter.com/raghavaroraa"
          target="_blank"
          rel="noopener noreferrer"
        >
          @raghavaroraa
        </a>{" "}
        for helping with design & ux.
      </footer>
    </div>
  );
}
