import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga";
import Mirror from "../../components/Mirror";
import Selection from "./Selection";
import Button from "../../components/Button";
import UploadImageButton from "../../components/UploadImageButton";
import { pixelize } from "./ImageHero";
import "./styles.css";

export default function EditImageScreen(props) {
  const [canvasNode, setCanvasNode] = useState(null);
  const [blurSelections, setBlurSelections] = useState([]);
  const imageEl = useRef(null);
  const canvasEl = useRef(null);

  const { imageData, setImageData } = props;

  function onImageLoad() {
    const img = imageEl.current;
    setImageData({
      ...imageData,
      dimensions: {
        height: img.naturalHeight,
        width: img.naturalWidth
      }
    });
  }

  function onAddSelection(selection) {
    if (!selection) {
      return;
    }

    const canvas = canvasEl.current;
    const transformedSelection = {
      left: Math.floor(
        selection.left / canvas.offsetWidth * imageData.dimensions.width
      ),
      top: Math.floor(
        selection.top / canvas.offsetHeight * imageData.dimensions.height
      ),
      width: Math.floor(
        selection.width / canvas.offsetWidth * imageData.dimensions.width
      ),
      height: Math.floor(
        selection.height / canvas.offsetHeight * imageData.dimensions.height
      )
    };

    setBlurSelections([...blurSelections, transformedSelection]);
  }

  useEffect(
    () => {
      if (imageData.dimensions) {
        const img = imageEl.current;
        const canvas = canvasEl.current;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(img, 0, 0);
        setCanvasNode(canvas);
      }
    },
    [imageData]
  );

  useEffect(
    () => {
      if (blurSelections.length && imageData.dimensions) {
        const selection = blurSelections[blurSelections.length - 1];
        if (!selection) {
          return;
        }

        const canvas = canvasEl.current;
        const ctx = canvas.getContext("2d");
        const canvasImageData = ctx.getImageData(
          0,
          0,
          imageData.dimensions.width,
          imageData.dimensions.height
        );

        pixelize(canvasImageData, imageData.dimensions, {
          x: selection.left,
          y: selection.top,
          width: selection.width,
          height: selection.height
        });

        ctx.putImageData(canvasImageData, 0, 0);
      }
    },
    [blurSelections, imageData.dimensions]
  );

  useEffect(() => {
    ReactGA.pageview("studio");
  }, []);

  function downloadImage() {
    const canvas = canvasEl.current;
    const link = document.createElement("a");
    link.download = imageData.file.name;
    link.href = canvas.toDataURL("image/png;base64");

    if (document.createEvent) {
      const e = document.createEvent("MouseEvents");
      e.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );

      link.dispatchEvent(e);
    } else if (link.fireEvent) {
      link.fireEvent("onclick");
    }
  }
  function cleanImage() {
    setBlurSelections([]);

    const img = imageEl.current;
    const canvas = canvasEl.current;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(img, 0, 0);
  }

  return (
    <div className="studioContainer">
      {imageData &&
        imageData.dimensions && (
          <React.Fragment>
            <div className={"studio"}>
              <canvas
                className={"studio__frame"}
                ref={canvasEl}
                width={imageData.dimensions.width}
                height={imageData.dimensions.height}
              />
              <Mirror target={canvasNode}>
                {mirrorEl => (
                  <Selection target={mirrorEl} onSelection={onAddSelection} />
                )}
              </Mirror>
            </div>
            <div className="actionTray">
              <UploadImageButton
                onChange={imageData => {
                  setBlurSelections([]);
                  setImageData(imageData);
                }}
                data-tooltip="Upload new image"
                className="tooltip-bottom"
              >
                Change Image
              </UploadImageButton>
              <Button
                onClick={cleanImage}
                data-tooltip="Remove all blurred sections"
                className="tooltip-bottom"
              >
                Reset
              </Button>
              <Button onClick={downloadImage} variant="solid">
                Download
              </Button>
            </div>
          </React.Fragment>
        )}
      <img
        crossOrigin=""
        className={"studioContainer__image"}
        src={imageData.url}
        onLoad={onImageLoad}
        ref={imageEl}
        alt="uploaded"
      />
    </div>
  );
}
