import React, { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import "./styles.css";

export default function Mirror({ target, children }) {
  const [layout, setLayout] = useState(null);
  const containerEl = useRef(null);

  const updateLayout = () => {
    if (!target) {
      return setLayout(null);
    }

    setTimeout(() => {
      const boundingBox = target.getBoundingClientRect();
      setLayout({ width: boundingBox.width, height: boundingBox.height });
    });
  };

  useEffect(() => {
    const handleWindowResize = debounce(updateLayout, 100, {
      trailing: true
    });

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(updateLayout, [target]);

  return (
    <div className={"mirror"} ref={containerEl} style={layout || {}}>
      {layout ? children(containerEl.current) : null}
    </div>
  );
}
