import React from "react";
import classnames from "classnames";
import "./styles.css";

export default function Button({
  className,
  variant = "bordered",
  children,
  ...otherProps
}) {
  return (
    <button
      className={classnames("appButton", className, `appButton--${variant}`)}
      {...otherProps}
    >
      {children}
    </button>
  );
}
