function averageBlock(imageData, imageDimensions, startX, startY, blockWidth) {
  const xMin = Math.max(0, startX);
  const xMax = xMin + blockWidth;
  const yMin = Math.max(0, startY);
  const yMax = yMin + blockWidth;
  const totalPixelCount = Math.pow(blockWidth, 2);

  let averagePixelData = [0, 0, 0, 0];

  let j = yMin;
  while (j <= yMax) {
    let i = xMin;
    while (i <= xMax) {
      const pixelIndex = (j * imageDimensions.width + i) * 4;
      const pixelData = imageData.data.slice(pixelIndex, pixelIndex + 4);
      averagePixelData = averagePixelData.map(
        (value, index) => value + pixelData[index]
      );

      i++;
    }
    j++;
  }

  averagePixelData = averagePixelData.map(value =>
    Math.floor(value / totalPixelCount)
  );

  j = yMin;
  while (j <= yMax) {
    let i = xMin;
    while (i <= xMax) {
      const pixelIndex = (j * imageDimensions.width + i) * 4;
      imageData.data[pixelIndex] = averagePixelData[0];
      imageData.data[pixelIndex + 1] = averagePixelData[1];
      imageData.data[pixelIndex + 2] = averagePixelData[2];
      imageData.data[pixelIndex + 3] = averagePixelData[3];

      i++;
    }
    j++;
  }
}

export function pixelize(
  imageData,
  imageDimensions,
  imageArea = { x: 350, y: 200, width: 100, height: 100 },
  pixelizationFactor = 5
) {
  const imageWidth = Math.floor(imageDimensions.width);
  const imageHeight = Math.floor(imageDimensions.height);

  let areaWidth = imageArea.width;
  if (imageArea.x + areaWidth > imageWidth) {
    areaWidth = imageWidth - imageArea.x;
  }

  let areaHeight = imageArea.height;
  if (imageArea.y + areaHeight > imageHeight) {
    areaHeight = imageHeight - imageArea.y;
  }

  const blockWidth = Math.floor(
    Math.min(areaWidth, areaHeight) / pixelizationFactor
  );

  let blockStartY = imageArea.y;
  while (blockStartY + blockWidth < imageArea.y + areaHeight) {
    let blockStartX = imageArea.x;
    while (blockStartX + blockWidth < imageArea.x + areaWidth) {
      averageBlock(
        imageData,
        imageDimensions,
        blockStartX,
        blockStartY,
        blockWidth
      );
      blockStartX += blockWidth;
    }

    blockStartY += blockWidth;
  }
}
