import React from "react";
import Button from "../Button";
import "./styles.css";

export default function(props) {
  const { onChange, children, ...otherProps } = props;

  return (
    <Button {...otherProps}>
      <input
        type="file"
        className={"uploadFileInput"}
        onChange={event => {
          const imageFile = event.target.files[0];

          if (!imageFile) {
            console.log("IMAGE FILE NOT FOUND!");
            return;
          }

          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              onChange({
                file: imageFile,
                url: reader.result
              });
            },
            false
          );

          reader.readAsDataURL(imageFile);
        }}
      />
      {children}
    </Button>
  );
}
