import React, { useState } from "react";
import Studio from "./screens/Studio";
import Landing from "./screens/Landing";

const SCREEN_MAP = {
  studio: Studio,
  landing: Landing
};

function App() {
  const [screen, setScreen] = useState("landing");
  const [imageData, setImageData] = useState(null);
  const CurrentScreenComponent = SCREEN_MAP[screen];

  return CurrentScreenComponent ? (
    <CurrentScreenComponent
      setScreen={setScreen}
      setImageData={setImageData}
      imageData={imageData}
    />
  ) : null;
}

export default App;
