import React from "react";
import PropTypes from "prop-types";

function getOffset(props: Props) {
  let offset = {
    top: 0,
    left: 0
  };
  if (props.offset) {
    offset = {
      ...props.offset
    };
  } else if (props.target) {
    const boundingBox = props.target.getBoundingClientRect();
    offset.top = boundingBox.top + window.scrollY;
    offset.left = boundingBox.left + window.scrollX;
  }

  return offset;
}

export default class Selection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectionBox: null,
      offset: null
    };

    try {
      const opts = Object.defineProperty({}, "passive", {
        get: () => {
          return (this._supportsPassive = true);
        }
      });
      window.addEventListener("testPassive", null, opts);
      window.removeEventListener("testPassive", null, opts);
    } catch (e) {}
  }

  componentDidMount() {
    this.reset();
    this.bind();
  }

  componentDidUpdate() {
    this.reset();
    this.bind();
  }

  componentWillUnmount() {
    this.reset();
    window.document.removeEventListener("mousemove", this.onMouseMove);
    window.document.removeEventListener("mouseup", this.onMouseUp);
  }

  bind = () => {
    this.props.target.addEventListener("mousedown", this.onMouseDown);
    this.props.target.addEventListener(
      "touchstart",
      this.onTouchStart,
      this._supportsPassive ? { passive: true } : false
    );
  };

  reset = () => {
    if (this.props.target) {
      this.props.target.removeEventListener("mousedown", this.onMouseDown);
      setTimeout(() => {
        this._offset = getOffset(this.props);
      });
    }
  };

  init = (e, x, y) => {
    if (this.props.ignoreTargets) {
      const Target = e.target;
      if (!Target.matches) {
        // polyfill matches
        const defaultMatches = s =>
          [].indexOf.call(window.document.querySelectorAll(s), this) !== -1;
        Target.matches =
          Target.matchesSelector ||
          Target.mozMatchesSelector ||
          Target.msMatchesSelector ||
          Target.oMatchesSelector ||
          Target.webkitMatchesSelector ||
          defaultMatches;
      }
      if (
        Target.matches &&
        Target.matches(this.props.ignoreTargets.join(","))
      ) {
        return false;
      }
    }

    const nextState = {};
    const zoom = this.props.zoom || 1;

    nextState.mouseDown = true;
    nextState.startPoint = {
      x: x * zoom - this._offset.left,
      y: y * zoom - this._offset.top
    };

    this.setState(nextState);
    return true;
  };

  /**
   * On root element mouse down
   * The event should be a MouseEvent | TouchEvent, but flow won't get it...
   * @private
   */
  onMouseDown = e => {
    if (
      this.props.disabled ||
      e.button === 2 ||
      (e.nativeEvent && e.nativeEvent.which === 2)
    ) {
      return;
    }

    if (this.init(e, e.pageX, e.pageY)) {
      window.document.addEventListener("mousemove", this.onMouseMove);
      window.document.addEventListener("mouseup", this.onMouseUp);
    }
  };

  onTouchStart = e => {
    if (
      this.props.disabled ||
      !e.touches ||
      !e.touches[0] ||
      e.touches.length > 1
    ) {
      return;
    }

    if (this.init(e, e.touches[0].pageX, e.touches[0].pageY)) {
      window.document.addEventListener("touchmove", this.onTouchMove);
      window.document.addEventListener("touchend", this.onMouseUp);
    }
  };

  /**
   * On document element mouse up
   * @private
   */
  onMouseUp = () => {
    window.document.removeEventListener("touchmove", this.onTouchMove);
    window.document.removeEventListener("mousemove", this.onMouseMove);
    window.document.removeEventListener("mouseup", this.onMouseUp);
    window.document.removeEventListener("touchend", this.onMouseUp);

    this.props.onSelection(this.state.selectionBox);

    this.setState({
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectionBox: null
    });
  };

  /**
   * On document element mouse move
   * @private
   */
  onMouseMove = e => {
    e.preventDefault();
    if (this.state.mouseDown) {
      const endPoint = {
        x: e.pageX - this._offset.left,
        y: e.pageY - this._offset.top
      };

      this.setState({
        endPoint,
        selectionBox: this.calculateSelectionBox(
          this.state.startPoint,
          endPoint
        )
      });
    }
  };

  onTouchMove = e => {
    e.preventDefault();
    if (this.state.mouseDown) {
      const endPoint = {
        x: e.touches[0].pageX - this._offset.left,
        y: e.touches[0].pageY - this._offset.top
      };

      this.setState({
        endPoint,
        selectionBox: this.calculateSelectionBox(
          this.state.startPoint,
          endPoint
        )
      });
    }
  };

  /**
   * Calculate selection box dimensions
   * @private
   */
  calculateSelectionBox = (startPoint, endPoint) => {
    if (!this.state.mouseDown || !startPoint || !endPoint) {
      return null;
    }

    // The extra 1 pixel is to ensure that the mouse is on top
    // of the selection box and avoids triggering clicks on the target.
    const left = Math.min(startPoint.x, endPoint.x) - 1;
    const top = Math.min(startPoint.y, endPoint.y) - 1;
    const width = Math.abs(startPoint.x - endPoint.x) + 1;
    const height = Math.abs(startPoint.y - endPoint.y) + 1;

    return {
      left,
      top,
      width,
      height
    };
  };

  /**
   * Render
   */
  render() {
    const style = {
      position: "absolute",
      background: "rgba(159, 217, 255, 0.3)",
      border: "solid 1px rgba(123, 123, 123, 0.61)",
      zIndex: 9,
      cursor: "crosshair",
      ...this.state.selectionBox,
      ...this.props.style
    };

    if (
      !this.state.mouseDown ||
      !this.state.endPoint ||
      !this.state.startPoint
    ) {
      return null;
    }
    return <div className="react-ds-border" style={style} />;
  }
}

Selection.propTypes = {
  target: PropTypes.object,
  disabled: PropTypes.bool,
  onSelection: PropTypes.func.isRequired,
  offset: PropTypes.object,
  zoom: PropTypes.number,
  style: PropTypes.object,
  ignoreTargets: PropTypes.array
};
